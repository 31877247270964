import React from "react"

const Footer = () => (
  <footer id="footer" className="dark">
    <div className="container">
      <div className="footer-widgets-wrap">
        <div className="row col-mb-50">
          <div className="col-lg-5">
            <div className="widget clearfix">
              <h3 style={{ color: "#e1a33e" }}>
                18th International Virtual Conference of <br /> Asia Pacific
                Association of <br /> Surgical Tissue Banks <br /> (APASTB 2021)
              </h3>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="widget clearfix">
              <h4>Address</h4>
              <span>
                CELL &amp; TISSUE BANK REGENERATIVE MEDICINE <br /> <br />
                Dr. Soetomo General Academic Hospital <br />
                Faculty of Medicine, Universitas Airlangga <br />
                Mayjen Prof. Dr. Moestopo 6-8 <br />
                Surabaya 60132
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="widget quick-contact-widget form-widget clearfix">
              <h4>Contact</h4>
              <div className="row col-mb-30">
                <div className="col-lg-6">
                  <span>
                    <abbr title="Phone Number 1">
                      <strong>dr. Ajeng</strong>
                    </abbr>
                    <br />
                    <span>(WhatsApp Only)</span>
                    <br />
                    <span>(+62) 8223 1188 768</span>
                  </span>
                </div>
                <div className="col-lg-6">
                  <span>
                    <a
                      href="mailto:apastb@bankjaringandansel.id"
                      className="button  button-circle button-border button-white button-light text-right m-0"
                    >
                      EMAIL US
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="copyrights">
      <div className="container">
        <div className="w-100 text-center">
          <a href="https://fk.unair.ac.id/" target="blank">
            Copyrights © {new Date().getFullYear()} All Rights Reserved by
            Instalasi Bank Jaringan dan Sel.
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
