import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { LOGOUT } from "../redux/actionTypes"
import navDesktop from "./images/navbar_desktop.png"
import navMobile from "./images/navbar_mobile.png"
import {
  LOGIN,
  ABOUT,
  COMMITTEES,
  DATES,
  SUBMISSION,
  GUIDELINES,
  DOWNLOADS,
} from "../constants/routes"
import { connect, useDispatch } from "react-redux"

const Header = ({ siteTitle, state }) => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState(null)
  const [menuStyle, setMenuStyle] = useState(null)

  const handleResize = () => {
    setMenuStyle(window.innerWidth < 992 ? menuStyle : null)
  }

  const toggleMenu = () => {
    if (window.innerWidth > 991) return null
    setMenuStyle(menuStyle ? null : { display: "block" })
  }

  useEffect(() => {
    setProfile(state.user.profile)
  }, [state.user.profile])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  })

  const getClassMenu = target => {
    if (window.location.pathname === target) return `menu-link color-apastd`
    return `menu-link`
  }

  return (
    <header id="header" className="full-header">
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <a
                href="/"
                className="standard-logo"
                data-dark-logo="images/logo-dark.png"
              >
                <img src={navDesktop} alt="nav mobile dekstop" />
              </a>
              <a
                href="/"
                className="retina-logo"
                data-dark-logo="images/logo-dark@2x.png"
              >
                <img src={navMobile} alt="nav mobile mobile" />
              </a>
            </div>
            <div className="header-misc">
              <div id="top-search" className="header-misc-btn">
                <Link
                  to={LOGIN}
                  className="button button-3d button-circle gradient-grey-orange"
                  onClick={() => {
                    if (profile) dispatch({ type: LOGOUT })
                  }}
                >
                  {profile ? "Logout" : "Login"}
                </Link>
              </div>
            </div>
            <div id="primary-menu-trigger">
              <svg
                className="svg-trigger"
                viewBox="0 0 100 100"
                onClick={toggleMenu}
              >
                <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                <path d="m 30,50 h 40" />
                <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
              </svg>
            </div>
            <nav className="primary-menu">
              <ul className="menu-container" style={menuStyle}>
                <li className="menu-item">
                  <Link to={ABOUT} className={getClassMenu(ABOUT)}>
                    About
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to={COMMITTEES} className={getClassMenu(COMMITTEES)}>
                    Committees
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to={DATES} className={getClassMenu(DATES)}>
                    Date
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to={SUBMISSION} className={getClassMenu(SUBMISSION)}>
                    Submission
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to={GUIDELINES} className={getClassMenu(GUIDELINES)}>
                    Author Guidelines
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to={DOWNLOADS} className={getClassMenu(DOWNLOADS)}>
                    Downloads
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="header-wrap-clone" />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default connect(state => ({ state }))(Header)
