export const LANDING = "/"
export const REGISTER = "/register"
export const FORGOT_PASSWORD = "/forgotPassword"
export const LOGIN = "/login"
export const PROFILE = "/profile"
export const DASHBOARD_REVIEWER = "/reviewerDashboard"
export const DASHBOARD_EDITOR = "/editorDashboard"
export const DASHBOARD_ADMIN = "/admin"
export const ABOUT = "/about"
export const COMMITTEES = "/committees"
export const DATES = "/dates"
export const SUBMISSION = "/submission"
export const GUIDELINES = "/guidelines"
export const DOWNLOADS = "/downloads"
export const REVIEWER_ABSTRACT_DETAIL = "/reviewerAbstractDetail"
export const EDITOR_ABSTRACT_DETAIL = "/editorAbstractDetail"
export const ABSTRACT_SUBMISSION = "/abstractSubmission"
export const ABSTRACT_DETAIL = "/abstractDetail"
export const WATCH = "/watch"
